import Image from '../../assets/drive-assets/erol-ahmed-FTy5VSGIfiQ-unsplash (1).jpg'
import Tick from './images/tick.svg'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { notification } from 'antd';

import { Button } from 'antd'

const Thankyou = () => {
	const navigator = useNavigate()
	const user = JSON.parse(localStorage.getItem('user'))

	const thankyouButtonPressed = async (userId) => {
        try {
            // Call backend API to approve the user
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}admin/thankyouButtonPressed`, { userId });
            if (response.status === 200) {
                // Update UI after approval
                        notification['success']({
                            message: 'Thank You',
                            duration: 5,
                            onClick: () => {
                                notification.close()
                            },
                        })
                console.log('Thank You status changed successfully');
            }
        } catch (error) {
            console.error('Error in changing the status of Thankyou', error);
        }
    };

	return (

		<div className=' min-h-screen max-h-[100vh] flex'>
			<div className='w-[50%] h-full hidden lg:block rounded-[0_80px_0px_0]'>
				<img className='w-full h-full object-cover' src={Image} alt='' />
			</div>
			<div className='w-[50%]  max-lg:w-full h-full flex flex-col justify-center items-center'>
				<div className='flex flex-col justify-center items-center  2xl:mt-60 mt-40'>
					<img className='w-[25%] object-cover pb-4' src={Tick} alt='' />
					<p className={`w-[60%] text-[#2A2A2A] font-[600] text-[18px] leading-[26px] pt-4 pb-4 text-center`}>
						Congratulations! Your account has been verified and successfully created. Welcome to City Swapp!
					</p>
					<div className='w-[60%] pt-4 text-center' onClick={() => { 
									thankyouButtonPressed(user?._id); 
									navigator('/home/about');
								}}>						
								<Button className='btn-primary' block >
									Get Started
								</Button>
							
						</div>
				</div>
			</div>
		</div>
	)
	
}

export default Thankyou
